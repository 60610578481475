import { Component, OnInit } from '@angular/core';
import { SeoService } from './services/shared/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private seoService: SeoService) {}

  ngOnInit() {
    this.seoService.updateTitle('California All - Early Learning & Care Playbook');
    this.seoService.updateDescription('Guidelines & resources for safely reopening early learning and care');
  }
}
